<template>
  <form class="view-admin-new-class" @submit.prevent="submit">
    <h4 class="mb-4">Informações da turma</h4>
    <form-group
      id="name"
      v-model="form.name"
      label="Nome da turma*"
      type="text"
      placeholder="Digite título da questão"
      :errors="errors.name"
      class="mb-4"
    />

    <form-group
      v-model="form.region"
      id="region"
      select
      label="Esta é uma turma de uma região específica?"
      placeholder="Selecione..."
      :errors="errors.region"
      :options="regionOptions"
    />

    <form-group
      v-model="form.deadline"
      id="deadline"
      isDate
      label="Quando as inscrições desta turma terminam?"
      placeholder="Ex: 14/10/2023"
      :errors="errors.deadline"
      class="mb-4"
    />
    <action-dispatcher action="classes/get">
      <hr />
      <h4 class="mb-4">Liberar prova</h4>
      <form-group
        v-model="form.exam_available_id"
        id="exam_available_id"
        select
        label="Deseja liberar uma prova para esta turma? Selecione o módulo"
        placeholder="Selecione..."
        :errors="errors.exam_available_id"
        :options="examOptions"
      />

      <form-group
        v-model="form.exam_expire_at"
        id="exam_expire_at"
        isDate
        label="Até quando a prova será liberada?"
        placeholder="Ex: 14/10/2023"
        :errors="errors.exam_expire_at"
      />
    </action-dispatcher>

    <div
      class="row align-items-center justify-content-between mt-5 pt-4 pt-lg-5"
    >
      <div class="col-lg-4 order-2 order-lg-1">
        <button
          type="button"
          class="btn btn-outline-secondary btn-block"
          @click="$router.go(-1)"
        >
          Voltar
        </button>
      </div>
      <div class="col-lg-4 order-1 order-lg-2">
        <button type="submit" class="btn btn-primary btn-block">
          <loading :show="submitLoading">
            Salvar
          </loading>
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import { formFields, clearObject } from "@/functions/helpers";
export default {
  mounted() {
    if (this.$route.params.id) {
      this.pageLoading = true;
      this.$store
        .dispatch("classes/getClass", this.$route.params.id)
        .then(data => {
          this.form = data;
        })
        .finally(() => {
          this.pageLoading = false;
        });
    }
  },
  computed: {
    examOptions() {
      return [
        { id: "", name: "Prova não liberada" },
        ...this.$store.state.content.modules
      ];
    },
    regionOptions() {
      return [
        { id: "Feira de Santana", name: "Feira de Santana" },
        { id: "Panambi", name: "Panambi" },
        { id: "Sertão", name: "Sertão" },
        { id: "Miguel Calmon", name: "Miguel Calmon" },
        { id: "Jacobina", name: "Jacobina" },
        { id: "Aluno Externo", name: "Aluno Externo" }
      ];
    }
  },
  data() {
    return {
      ...formFields(
        ["name", "deadline", "region", "exam_available_id", "exam_expire_at"],
        {}
      ),
      pageLoading: false,
      submitLoading: false
    };
  },
  methods: {
    submit() {
      if (this.submitLoading) return;

      if (!this.form?.name || !this.form?.name.length) {
        this.$message.warning("Por favor, digite o nome da turma");
        return;
      }

      if (this.form.exam_available_id && !this.form.exam_expire_at) {
        this.$message.warning(
          "Por favor, indique até quando esta prova estará disponível"
        );
        return;
      }

      const action = this.$route.params.id ? "classes/put" : "classes/post";

      const payload = { ...this.form, created_at: new Date().toISOString() };

      let msg = "Turma adicionada com sucesso!";

      if (this.$route.params.id) {
        msg = "Turma editada com sucesso!";
        payload.id = this.$route.params.id;
      }

      this.submitLoading = true;

      this.$store
        .dispatch(action, clearObject(payload))
        .then(() => {
          this.$message.success(msg);
          this.$router.push({
            name: "admin-classes"
          });
        })
        .catch(e => {
          this.$message.error("Não foi possível cadastrar a nova turma");
        })
        .finally(() => {
          this.submitLoading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.view-admin-new-class {
}
</style>
